import React from 'react';
import { Redirect } from 'react-router';
import axios from 'axios';
import moment from 'moment';

import Auxi from '../../hoc/Auxi';
import Spinner from '../../components/Widgets/Spinner';
import Toast from '../../components/Widgets/CustomToast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfinity } from '@fortawesome/free-solid-svg-icons'

import infinitySvg from './infinity-symbol.svg'


import '../../assets/css/myAccount.css';

class Index extends React.Component{

    state = {
        cloudId : "FONUSMOBILE",
        myNumbersClicked: false,
        voicemailGreetingClicked: false,
        loading: false,
        userCredit: "0",
        balanceReturned: false,
    };

    componentDidMount() {
        const encodedUrlString = window.location.href.replace(/\+/g, '%2B');
        const url = new URL(encodedUrlString);
        const cloudUsername = url.searchParams.get("cloud_username");
        const cloudPassword = url.searchParams.get("cloud_password");
        this.setState({cloudUsername: cloudUsername});
        this.setState({cloudPassword: cloudPassword});
        document.title = 'Number Selection - Fonus';

        this.getBalance(cloudUsername, cloudPassword);
        this.getSmsCredit(cloudUsername, cloudPassword);
    }

    getBalance = (cloudUsername, cloudPassword) => {
        const payLoad = {
            cloud_username: cloudUsername,
            cloud_password: cloudPassword,
            cloud_id: this.state.cloudId
        };
        
        axios.post(`${process.env.REACT_APP_API_URL}getLinksFieldBalance`, payLoad)
        .then(response => {
            let remainingData =  response.data.remaining_data.toFixed(2);
            let totalData = response.data.total_data;
            

            if (totalData === 35){
                remainingData = "unlimited"
            }

            if( remainingData < 0){
                remainingData = 0;
            }

            let expiryDate = response.data.expiry_date
            expiryDate = moment(expiryDate).format("MMM DD")

            let eventdate = moment(response.data.expiry_date);
            let todaysdate = moment();
            let daysLeft =  eventdate.diff(todaysdate, 'days');

            this.setState({remainingData: remainingData})
            this.setState({expiryDate: expiryDate})
            this.setState({daysLeft: daysLeft})
            this.setState({balanceReturned: true})


            this.setState({loading: false});
        }).catch(err => {
            console.log(err)
            this.setState({balanceReturned: false})
            this.setState({loading: false});
            //this.getTataBalance(cloudUsername, cloudPassword);
        });
    }

    getTataBalance = (cloudUsername, cloudPassword) => {
        const payLoad = {
            cloud_username: cloudUsername,
            cloud_password: cloudPassword,
            cloud_id: this.state.cloudId
        };
        
        axios.post(`${process.env.REACT_APP_API_URL}getTataBalance`, payLoad)
        .then(response => {

            // 5, 20 and 35
            const gig5 = (5 * 1024).toFixed(0)
            const gig20 = (20 * 1024).toFixed(0)
            let remainingData =  response.data.balances[1].outstandingAmount;
            if (response.data.balances[0].initialAmount.toFixed(0) === gig5 || response.data.balances[0].initialAmount.toFixed(0) === gig20){
                remainingData =  response.data.balances[0].outstandingAmount;
            }

            remainingData = remainingData / 1024;
            remainingData = remainingData.toFixed(2)
            

            if (response.data.balances[0].unitType === "unlimited"){
                remainingData = "unlimited"
            }

            let expiryDate = response.data.balances[0].expirationDate
            expiryDate = moment(expiryDate).format("MMM DD")

            let eventdate = moment(response.data.balances[0].expirationDate);
            let todaysdate = moment();
            let daysLeft =  eventdate.diff(todaysdate, 'days');

            this.setState({remainingData: remainingData})
            this.setState({expiryDate: expiryDate})
            this.setState({daysLeft: daysLeft})
            this.setState({balanceReturned: true})


            this.setState({loading: false});
        }).catch(err => {
            //Toast.error(err.response)
            this.setState({balanceReturned: false})
            this.setState({loading: false});
        });
    }

    getSmsCredit = (cloudUsername, cloudPassword) => {
        console.log("innnn ssmmsmsmsms credit")
        const payLoad = {
            cloud_username: cloudUsername,
            cloud_password: cloudPassword,
            cloud_id: this.state.cloudId
        };
        axios.post(`${process.env.REACT_APP_API_URL}billing/getUserBalance`, payLoad)
        .then(response => {
            this.setState({userCredit: response.data.balance})
        }).catch(err => {
            //Toast.error(err.response)
        });
    }

    render() {
        let redirect = null;
        if(this.state.myNumbersClicked){
            this.setState({myNumbersClicked: false});
            redirect = <Redirect to = {"/number_selection?cloud_username="+this.state.cloudUsername+"&cloud_password="+this.state.cloudPassword} />;

        }
        if(this.state.voicemailGreetingClicked){
            this.setState({voicemailGreetingClicked: false});
            redirect = <Redirect to = {"/voicemail_greeting?cloud_username="+this.state.cloudUsername+"&cloud_password="+this.state.cloudPassword} />;
        }
        if(this.state.addOnsClicked){
            this.setState({addOnsClicked: false});
            redirect = <Redirect to = {"/add_ons?cloud_username="+this.state.cloudUsername+"&cloud_password="+this.state.cloudPassword} />;
        }

        return (
            <Auxi>
                {redirect}
                <div className = "my-account-page">
                    <div className="row outer-container">
                        <h3 className="m-b-10 loader-center">
                            {this.state.loading
                                ? <Spinner data="" />
                                : null
                            }
                        </h3>
                        <div className="col-md-12">
                            <h1 className="my-account-heading"> Account Summary </h1>
                        </div>
                        {
                            this.state.balanceReturned? 
                                <div className="col-md-12">
                                    <div className="balance-container">
                                        <div className="balance">
                                            {
                                                this.state.remainingData === "unlimited"?
                                                    <object data={infinitySvg} height="50px" width="60px" />
                                                : 
                                                    <h2>{this.state.remainingData} GB</h2>
                                            }
                                            
                                        </div>
                                        <div className="balance-remaining">
                                            <h2>fast data remaining</h2>
                                        </div>

                                        <div className="renewal-message">
                                            <p>this month</p>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                        

                        <div className="col-md-12" style={{marginTop:"10px"}}>
                        {
                            this.state.userCredit !== "0" && this.state.userCredit !== "0.00"?
                                <div className="button-container">
                                    <h4>${this.state.userCredit}</h4>
                                    <span>Global SMS credit</span>
                                </div>
                            :null
                        }
                            

                            <div className="button-container" onClick={e => this.setState({myNumbersClicked:true})}>
                                <h4>My Numbers</h4>
                                <i className="feather icon-chevron-right"> </i>
                            </div>
                            
                            <div className="button-container" onClick={e => this.setState({voicemailGreetingClicked:true})}>
                                <h4>Voicemail Greeting</h4>
                                <i className="feather icon-chevron-right"> </i>
                            </div>
                                
                            <div className="button-container" onClick={e => this.setState({addOnsClicked:true})}>
                                <h4>Add-Ons</h4>
                                <i className="feather icon-chevron-right"> </i>
                            </div>
                            
                        </div>
                    </div>

                </div>

            </Auxi>

        );

    }

}

export default Index;
